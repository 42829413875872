<div
  fxLayout="row wrap"
  fxLayoutAlign="stretch"
  fxLayout.lt-md="column"
  [fxLayoutGap]="(1 | dsSpacing) + ' grid'"
  *ngIf="claim"
>
  <div fxFlex="1 0 300px" fxFlex.lt-md="auto">
    <h6>{{ 'cos.general' | translate }}</h6>
    <mat-card>
      <mat-card-content>
        <table list>
          <tr>
            <td class="min-width-cell">{{ 'cos.repair_date' | translate }}:</td>
            <td>
              {{
                claim.repair_date
                  ? (claim.repair_date | date: 'shortDate')
                  : ('general.not_available' | translate)
              }}
            </td>
          </tr>
          <tr>
            <td>{{ 'cos.creation_date' | translate }}:</td>
            <td>
              {{
                claim.notification_date
                  ? (claim.notification_date | date: 'shortDate')
                  : ('general.not_available' | translate)
              }}
            </td>
          </tr>
          <tr>
            <td>{{ 'cos.product_line' | translate }}:</td>
            <td>
              {{ claim.product_line || ('general.not_available' | translate) }}
            </td>
          </tr>
          <tr *ngIf="claim.responsible_person as person">
            <td>{{ 'cos.responsible_person' | translate }}:</td>
            <td>
              {{ person.name }}
              <mat-icon
                *ngIf="person.email"
                class="mail-to"
                inline
                [matTooltip]="person.email"
                (click)="mailTo(person.email)"
                >email</mat-icon
              >
            </td>
          </tr>
        </table>
      </mat-card-content>
    </mat-card>
  </div>
  <div fxFlex="1 0 300px" fxFlex.lt-md="auto">
    <h6>{{ 'cos.financial_info' | translate }}</h6>
    <mat-card>
      <mat-card-content>
        <table list>
          <tr>
            <td>{{ 'cos.requested' | translate }}:</td>
            <td>
              {{
                claim.positions || []
                  | cosTotalClaimPipe: 'requested'
                  | currency: 'EUR'
              }}
            </td>
          </tr>
          <tr>
            <td>{{ 'cos.accepted' | translate }}:</td>
            <td>
              {{
                claim.positions || []
                  | cosTotalClaimPipe: 'accepted'
                  | currency: 'EUR'
              }}
            </td>
          </tr>
          <tr>
            <td class="align-center">{{ 'cos.refunded' | translate }}:</td>
            <td>
              <div class="spinner-inside">
                <mat-progress-spinner
                  [value]="100"
                  mode="determinate"
                  [diameter]="50"
                  [strokeWidth]="3"
                  class="bg"
                  color="accent"
                >
                </mat-progress-spinner>
                <mat-progress-spinner
                  [value]="
                    (claim.positions || [] | cosTotalClaimPipe: 'progress') || 5
                  "
                  mode="determinate"
                  [diameter]="50"
                  [strokeWidth]="3"
                >
                </mat-progress-spinner>
                <span
                  >{{
                    claim.positions || [] | cosTotalClaimPipe: 'progress'
                  }}%</span
                >
              </div>
            </td>
          </tr>
        </table>
      </mat-card-content>
    </mat-card>
  </div>
  <div fxFlex="1 0 500px" fxFlex.lt-md="auto">
    <h6>{{ 'cos.error_description' | translate }}</h6>
    <ds-expansion-panel
      [zIndex]="2"
      angulartics2On="click"
      angularticsCategory="ClaimOnSuppliers"
      angularticsLabel="ClaimOverview"
      angularticsAction="ExpandDescription"
    >
      <div class="description">
        {{ claim.error_description || ('general.not_available' | translate) }}
      </div>
    </ds-expansion-panel>
  </div>
</div>
