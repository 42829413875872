<h3>{{ 'cos.attachments' | translate }}</h3>
<ds-attachments
  [documentOptions]="fileOptions"
  [attachments]="attachmentsToShow"
  [readOnlyMode]="readonlyMode"
  (fileSelect)="uploadFiles($event)"
>
  <ng-template let-file #actions>
    <button
      angulartics2On="click"
      angularticsCategory="ClaimOnSuppliers"
      angularticsLabel="Attachments"
      angularticsAction="DeleteFile"
      mat-icon-button
      (click)="deleteFile(file)"
      [matTooltip]="'delete'"
      [disabled]="file.sent_to_sap"
      [dsButtonPending]="file.delete_pending"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </ng-template>
</ds-attachments>
